import Model from '@/shared/classes/model'
import Company from '@/shared/modules/company/company.model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IToolCategoryResponse from '@/shared/modules/tool-category/interfaces/tool-category-response.interface'

export default class ToolCategory extends Model<IModelResponse> {
  static ALIAS: string = 'tool-category'

  uuid!: string
  name!: string

  quantity!: number

  company_uuid!: string
  company!: Company

  transform(data: IToolCategoryResponse): void {
    super.transform(data)

    this.uuid = data.uuid
    this.name = data.name
    this.quantity = data.quantity
    this.company_uuid = data.company_uuid
    
    this.company = this.setRelation(Company, data.company)
  }
}
