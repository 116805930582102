










import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import ToolCategory from '@/shared/modules/tool-category/models/tool-category.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import router from '@/shared/router'
import can from '@/shared/helpers/can.helper'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import ShowAction from '@/shared/classes/components/data-table/default-actions/show-action'
import ToolLocation from '@/shared/modules/tool-location/models/tool-location.model'
import ToolLocationService from '@/shared/modules/tool-location/tool-location.service'
import ToolCategoryService from '@/shared/modules/tool-category/tool-category.service'

@Component({
    components: { Can, DataTable, PageHeader },
    methods: { __ }
  })
  export default class ToolsCategoriesIndex extends Vue {
    permissions = permissions
    table: DataTableBase|null = null

    created() {
      this.table = new DataTableBase()
        .setModel(ToolCategory)
        .setEndpoint(`company/${ this.company.slug }/tool-categories`)
        .setDoubleClick(this.editToolCategory)
        .setHeaders([
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('name')
            .setText(__('company.views.tool-categories.index.table.columns.category'))
            .setMeta({
              getRawLocation: (tool: ToolCategory) => ({
                path: `${ this.$router.currentRoute.path }/${ tool.uuid }/edit`
              })
            }),
          new DataTableHeader()
            .setKey('quantity')
            .setSortable(false)
            .setText(__('company.views.tool-categories.index.table.columns.quantity'))
        ])
        .setFilters([
          new DataTableFilter()
            .setOperator(FilterOperators.notNulled)
            .setKeyIsPrimary()
            .setField(
              new Field()
                .setType(FieldTypes.checkbox)
                .setKey('deactivated_at')
                .setTitle(__('company.views.tool-locations.index.table.filters.show-deactivated'))
            )
        ])
        .setDefaultFilters([
          {
            operator: FilterOperators.notNulled,
            key: 'deactivated_at',
            value: false,
          }
        ])
        .setActions([
          new EditAction()
            .setPermissions([
              permissions.company.toolCategories.show,
              permissions.company.toolCategories.update,
            ]),
          new DataTableAction()
            .setPermissions([
              permissions.company.toolLocations.deactivate,
            ])
            .setIcon('mdi-delete-outline')
            .setTitle(__('company.views.tools.index.table.actions.deactivate.title'))
            .setAction(async (item: ToolCategory) => {
              await ToolCategoryService.deactivate(this.company, item.uuid)

              await this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('company.views.tool-categories.index.table.actions.deactivate.on-success')
              })

              this.table?.removeItem(item)
            }),
        ])
    }

    navigateToCreate(): void {
      this.$router.push({ name: CompanyRoutes.toolCategoriesCreate })
    }

    editToolCategory(item: ToolCategory): void {
      if (this.user.hasDynamicPermissions([permissions.company.toolCategories.show, permissions.company.toolCategories.update])) {
        this.$router.push({ path: `${ router.currentRoute.path }/${ item.uuid }/edit`})
      }
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
