





import { Component, Prop, Vue } from 'vue-property-decorator'
import can from '@/shared/helpers/can.helper'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

@Component
export default class Can extends Vue {
  @Prop() permissions!: string[]
  @Prop() entry!: Model<IModelResponse>|null
  // At least one permission needed to pass through if atLeastOne = true
  @Prop() atLeastOne!: boolean

  get canAccess(): boolean {
    if (! this.permissions) return true

    return can(this.permissions, this.atLeastOne, this.entry)
  }
}
